export const partnersData = [
  {
    title: 'aws',
    fluid: {
      aspectRatio: 1.66,
      src:
        '//images.ctfassets.net/1n69jtns7cd1/4gh9G4u5q8RN5TTMyzutsq/d7bf5faa3803988d38139a2f548225e7/aws-logo.png?w=1000&q=50',
      srcSet:
        '//images.ctfassets.net/1n69jtns7cd1/4gh9G4u5q8RN5TTMyzutsq/d7bf5faa3803988d38139a2f548225e7/aws-logo.png?w=250&h=150&q=50 250w,\n//images.ctfassets.net/1n69jtns7cd1/4gh9G4u5q8RN5TTMyzutsq/d7bf5faa3803988d38139a2f548225e7/aws-logo.png?w=400&h=240&q=50 400w',
      srcWebp:
        '//images.ctfassets.net/1n69jtns7cd1/4gh9G4u5q8RN5TTMyzutsq/d7bf5faa3803988d38139a2f548225e7/aws-logo.png?w=1000&q=50&fm=webp',
      srcSetWebp:
        '//images.ctfassets.net/1n69jtns7cd1/4gh9G4u5q8RN5TTMyzutsq/d7bf5faa3803988d38139a2f548225e7/aws-logo.png?w=250&h=150&q=50&fm=webp 250w,\n//images.ctfassets.net/1n69jtns7cd1/4gh9G4u5q8RN5TTMyzutsq/d7bf5faa3803988d38139a2f548225e7/aws-logo.png?w=400&h=240&q=50&fm=webp 400w',
      sizes: '(max-width: 1000px) 100vw, 1000px',
    },
  },
  {
    title: 'Google Cloud Platform',
    fluid: {
      aspectRatio: 1.66,
      src:
        '//images.ctfassets.net/1n69jtns7cd1/76zGONVnmopxSFG0YD9y5V/65b93874ef8116ff7c67255b49604133/GCP-logo.png?w=1000&q=50',
      srcSet:
        '//images.ctfassets.net/1n69jtns7cd1/76zGONVnmopxSFG0YD9y5V/65b93874ef8116ff7c67255b49604133/GCP-logo.png?w=250&h=150&q=50 250w,\n//images.ctfassets.net/1n69jtns7cd1/76zGONVnmopxSFG0YD9y5V/65b93874ef8116ff7c67255b49604133/GCP-logo.png?w=400&h=240&q=50 400w',
      srcWebp:
        '//images.ctfassets.net/1n69jtns7cd1/76zGONVnmopxSFG0YD9y5V/65b93874ef8116ff7c67255b49604133/GCP-logo.png?w=1000&q=50&fm=webp',
      srcSetWebp:
        '//images.ctfassets.net/1n69jtns7cd1/76zGONVnmopxSFG0YD9y5V/65b93874ef8116ff7c67255b49604133/GCP-logo.png?w=250&h=150&q=50&fm=webp 250w,\n//images.ctfassets.net/1n69jtns7cd1/76zGONVnmopxSFG0YD9y5V/65b93874ef8116ff7c67255b49604133/GCP-logo.png?w=400&h=240&q=50&fm=webp 400w',
      sizes: '(max-width: 1000px) 100vw, 1000px',
    },
  },
  {
    title: 'Cloudflare',
    fluid: {
      aspectRatio: 1.66,
      src:
        '//images.ctfassets.net/1n69jtns7cd1/1lw1l8iU2joAjRxoJtjY7e/02f47f069fa7a4e1323a2612e4d2feb6/CF_partnernetwork_logo_stacked_blktype.png?w=1000&q=50',
      srcSet:
        '//images.ctfassets.net/1n69jtns7cd1/1lw1l8iU2joAjRxoJtjY7e/02f47f069fa7a4e1323a2612e4d2feb6/CF_partnernetwork_logo_stacked_blktype.png?w=250&h=150&q=50 250w,\n//images.ctfassets.net/1n69jtns7cd1/1lw1l8iU2joAjRxoJtjY7e/02f47f069fa7a4e1323a2612e4d2feb6/CF_partnernetwork_logo_stacked_blktype.png?w=400&h=240&q=50 400w,\n//images.ctfassets.net/1n69jtns7cd1/1lw1l8iU2joAjRxoJtjY7e/02f47f069fa7a4e1323a2612e4d2feb6/CF_partnernetwork_logo_stacked_blktype.png?w=516&h=234&q=50 516w',
      srcWebp:
        '//images.ctfassets.net/1n69jtns7cd1/1lw1l8iU2joAjRxoJtjY7e/02f47f069fa7a4e1323a2612e4d2feb6/CF_partnernetwork_logo_stacked_blktype.png?w=1000&q=50&fm=webp',
      srcSetWebp:
        '//images.ctfassets.net/1n69jtns7cd1/1lw1l8iU2joAjRxoJtjY7e/02f47f069fa7a4e1323a2612e4d2feb6/CF_partnernetwork_logo_stacked_blktype.png?w=250&h=150&q=50&fm=webp 250w,\n//images.ctfassets.net/1n69jtns7cd1/1lw1l8iU2joAjRxoJtjY7e/02f47f069fa7a4e1323a2612e4d2feb6/CF_partnernetwork_logo_stacked_blktype.png?w=400&h=240&q=50&fm=webp 400w,\n//images.ctfassets.net/1n69jtns7cd1/1lw1l8iU2joAjRxoJtjY7e/02f47f069fa7a4e1323a2612e4d2feb6/CF_partnernetwork_logo_stacked_blktype.png?w=516&h=234&q=50&fm=webp 516w',
      sizes: '(max-width: 1000px) 100vw, 1000px',
    },
  },
  {
    title: 'ServiceNow',
    fluid: {
      aspectRatio: 1.66,
      src:
        '//images.ctfassets.net/1n69jtns7cd1/4cFowiW2FpmrDKZXptHS47/cff2910f9be114cae6e3ea9b403d0d0b/servicenow-logo.png?w=1000&q=50',
      srcSet:
        '//images.ctfassets.net/1n69jtns7cd1/4cFowiW2FpmrDKZXptHS47/cff2910f9be114cae6e3ea9b403d0d0b/servicenow-logo.png?w=250&h=150&q=50 250w,\n//images.ctfassets.net/1n69jtns7cd1/4cFowiW2FpmrDKZXptHS47/cff2910f9be114cae6e3ea9b403d0d0b/servicenow-logo.png?w=400&h=240&q=50 400w',
      srcWebp:
        '//images.ctfassets.net/1n69jtns7cd1/4cFowiW2FpmrDKZXptHS47/cff2910f9be114cae6e3ea9b403d0d0b/servicenow-logo.png?w=1000&q=50&fm=webp',
      srcSetWebp:
        '//images.ctfassets.net/1n69jtns7cd1/4cFowiW2FpmrDKZXptHS47/cff2910f9be114cae6e3ea9b403d0d0b/servicenow-logo.png?w=250&h=150&q=50&fm=webp 250w,\n//images.ctfassets.net/1n69jtns7cd1/4cFowiW2FpmrDKZXptHS47/cff2910f9be114cae6e3ea9b403d0d0b/servicenow-logo.png?w=400&h=240&q=50&fm=webp 400w',
      sizes: '(max-width: 1000px) 100vw, 1000px',
    },
  },
  {
    title: 'Snowflake',
    fluid: {
      aspectRatio: 1.66,
      src:
        '//images.ctfassets.net/1n69jtns7cd1/5FLPYFnkE2qAjeOoh05KTR/4cad837167833799b990decccae1a562/snowflake-logo.png?w=1000&q=50',
      srcSet:
        '//images.ctfassets.net/1n69jtns7cd1/5FLPYFnkE2qAjeOoh05KTR/4cad837167833799b990decccae1a562/snowflake-logo.png?w=250&h=141&q=50 250w,\n//images.ctfassets.net/1n69jtns7cd1/5FLPYFnkE2qAjeOoh05KTR/4cad837167833799b990decccae1a562/snowflake-logo.png?w=365&h=206&q=50 365w',
      srcWebp:
        '//images.ctfassets.net/1n69jtns7cd1/5FLPYFnkE2qAjeOoh05KTR/4cad837167833799b990decccae1a562/snowflake-logo.png?w=1000&q=50&fm=webp',
      srcSetWebp:
        '//images.ctfassets.net/1n69jtns7cd1/5FLPYFnkE2qAjeOoh05KTR/4cad837167833799b990decccae1a562/snowflake-logo.png?w=250&h=141&q=50&fm=webp 250w,\n//images.ctfassets.net/1n69jtns7cd1/5FLPYFnkE2qAjeOoh05KTR/4cad837167833799b990decccae1a562/snowflake-logo.png?w=365&h=206&q=50&fm=webp 365w',
      sizes: '(max-width: 1000px) 100vw, 1000px',
    },
  },
  {
    title: 'Vanta',
    fluid: {
      aspectRatio: 1.66,
      src:
        '//images.ctfassets.net/1n69jtns7cd1/5Bw6LdchzdrCvrjZwzqXHr/b62e276ba711719298a16227b881885b/vanta-logo.png?w=1000&q=50',
      srcSet:
        '//images.ctfassets.net/1n69jtns7cd1/5Bw6LdchzdrCvrjZwzqXHr/b62e276ba711719298a16227b881885b/vanta-logo.png?w=250&h=150&q=50 250w,\n//images.ctfassets.net/1n69jtns7cd1/5Bw6LdchzdrCvrjZwzqXHr/b62e276ba711719298a16227b881885b/vanta-logo.png?w=400&h=240&q=50 400w',
      srcWebp:
        '//images.ctfassets.net/1n69jtns7cd1/5Bw6LdchzdrCvrjZwzqXHr/b62e276ba711719298a16227b881885b/vanta-logo.png?w=1000&q=50&fm=webp',
      srcSetWebp:
        '//images.ctfassets.net/1n69jtns7cd1/5Bw6LdchzdrCvrjZwzqXHr/b62e276ba711719298a16227b881885b/vanta-logo.png?w=250&h=150&q=50&fm=webp 250w,\n//images.ctfassets.net/1n69jtns7cd1/5Bw6LdchzdrCvrjZwzqXHr/b62e276ba711719298a16227b881885b/vanta-logo.png?w=400&h=240&q=50&fm=webp 400w',
      sizes: '(max-width: 1000px) 100vw, 1000px',
    },
  },
];

export default partnersData;
